<template>
	<div id="service" class="service" ref="service">
		<!-- 头部 nav导航 -->
		<header-nav :tab="true"></header-nav>
		<!-- 售后服务-->
		<div class="content-box">
			<!-- 小屏 -->
			<div class="about-logo" v-if="is_vertical">
				<img src="/images/service-bg.png" style="width: 100%;height: 100%;"/>
			</div>
			<div class="about-logo-top" style="margin-top: 30px;">
				<div class="c1">宁德市千雪制冷设备有限公司售后如何？</div>
				<div class="t3">可针对不同客户需求提供灵活多样的维护保养协议，并根据特殊需要为您定制适合保养方案。</div>
				
				<div class="c1" style="margin-top: 30px;">服务内容包括：</div>
				<div class="t3">一、免费提供技术指导</div>
				<div class="t3">内容：机组的日常维修管理</div>
				<div class="t3" style="margin-top: 30px;">二、质保期内的维修服务</div>
				<div class="t3">1、 产品提供12个月的免费维修保养服务</div>
				<div class="t3">2、 接到故障申报后24小时内赶到现场</div>
				<div class="t3">3、 提供： ①普通故障24小时内修复 ②更换零部件48小时内修复</div>
				<div class="t3" style="margin-top: 30px;">三、质保期外的维修服务</div>
				<div class="t3">1、 终身跟踪维护</div>
				<div class="t3">2、 成本价提供设备零部件</div>
				<div class="t3">3、 成本价提供维修保养 客户服务部电话：136-1608-8271</div>
			
			</div>
		</div>
		
		<!-- 底部链接 -->
		<!-- 友情链接 -->
		<section class="friend uni-flex-column uni-flex-center" style="margin-top: 30px;" v-if="!is_vertical">
			<div class="friend-content">
				<div class="friend-text">
					<span>友情链接：</span>
					<a href="https://shop117115684.taobao.com/?spm=a2142.8996326.sellercard.15.146f2be3VrqbMR">千雪制冷淘宝店</a>
				</div>
			</div>
		</section>
		<!-- 联系我们 -->
		<!-- 小屏 -->
		<section class="foot uni-flex-column uni-flex-center" ref="contact" v-if="is_vertical">
			<div class="foot-content">
				<div class="foot-logo">
					<img src="/images/qrcode.png" style="width: 100%;height: 100%;"/>
				</div>
				<div class="foot-scan">微信扫一扫</div>
				<div class="foot-text">
					<span>copyright © 2020-2021 ALL RIGHT RESERVED 宁德市千雪制冷设备有限公司 版权所有</span>
					<span>地址：宁德市蕉城区古溪新村23号</span>
					<span>备案号：闽ICP备20014190号</span>
					<span>电话：13616088271</span>
				</div>
			</div>
		</section>
		<!-- 大屏 -->
		<section class="foot uni-flex-column uni-flex-center" ref="contact" v-else>
			<div class="foot-content">
				<div class="foot-text">
					<span>copyright © 2020-2021 ALL RIGHT RESERVED 宁德市千雪制冷设备有限公司 版权所有</span>
					<span>地址：宁德市蕉城区古溪新村23号</span>
					<span>备案号：闽ICP备20014190号</span>
					<span>电话：13616088271</span>
				</div>
				<div class="foot-logo">
					<img src="/images/qrcode.png" style="width: 100%;height: 100%;"/>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import api from '@/utils/api.js';
	import headerNav from '@/components/header.vue';
	export default {
		name: 'service', // 售后服务
		components: {
			headerNav,
		},
		data() {
			return {
				is_vertical: false,  //false 小屏  true 大屏
				win_width: document.body.clientWidth,
				
			}
		},
		computed: {
		},
		created() {
			window.scrollTo({
			    left: 0,
			    top: 0,
			    behavior: 'smooth'
			})
		},
		mounted() {
			/* 获取页面高宽 */
			// this.body_width = document.getElementById('service').offsetWidth;
			// this.body_height = document.getElementById('service').clientHeight;
			const that = this;
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth;
					that.win_width = window.screenWidth;
				})();
			};
			console.log(this.win_width);
			if (this.win_width < 768) {
				//小屏
				this.is_vertical = true;
			} else if (this.win_width >= 768) {
				//大屏
				this.is_vertical = false;
			}
		},
		watch: {
		},
		methods: {

		}
	}
</script>

<style scoped="" lang="less">
	.yx-h5 {
		font-size: 14px;
	}

	.service {
		height: 100%;
		overflow: auto;
		font-size: 20px;
		color: #bdbdbd;
		background-color: #FFFFFF;
	}
	
	.content-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		.about-logo {
			width: 100%;
			height: 900px;
		}
		
		.about-logo-top {
			width: 1200px;
			margin: 50px 15px;
			text-align: left;
			.about-logo2 {
				width: 300px;
				height: 200px;
			}
			.about-logo4 {
				width: 100%;
				height: 800px;
			}
			
			.t1 {
				margin-top: 30px;
				font-size: 36px;
				font-family: Source Han Sans CN;
				font-weight: bold;
				color: #111111;
				line-height: 80px;
			}
			.t2 {
				font-size: 30px;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #111111;
				line-height: 50px;
			}
			.t3 {
				margin: 10px 0;
				font-size: 20px;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #111111;
				line-height: 30px;
			}
			.t4 {
				margin: 10px 0;
				font-size: 18px;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #111111;
			}
			
			.c1 {
				color: #0E82D9;
				font-size: 30px;
				font-family: Montserrat;
				font-weight: bold;
				line-height: 40px;
			}
			
			.p1 {
				font-size: 35px;
				font-family: Montserrat;
				font-weight: bold;
				color: #000000;
				line-height: 50px;
			}
			
			.blue-line {
				margin: 15px 0;
				width: 100px;
				height: 4px;
				background: #0E82D9;
			}
		}
	}

	.friend {
		margin-top: 30px;
		height: 50px;
		background-color: #F5F5F5;
		
		.friend-content {
			display: flex;
			flex-direction: wrap;
			width: 1200px;
			
			.friend-text {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 24px;
			}
		}
	}
	
	.foot {
		height: 200px;
		background-color: #0E82D9;
		
		.foot-content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 1200px;
			
			.foot-text {
				display: flex;
				flex-direction: column;
				text-align: left;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 24px;
				.span {
				}
			}
			
			.foot-logo {
				width: 142px;
				height: 142px;
			}
		}
	}

	/* 手机端 */
	@media (orientation:portrait) and (max-width:890px) {
		
		.service {
			overflow-y: auto;
			overflow-x: hidden;
		}
		
		.content-box {
			width: 100%;
			.about-logo {
				width: 100%;
				height: 300px !important;
			}
			
			.about-logo-top {
				width: 90%;
				margin: 30px 0;
				text-align: left;
				.about-logo2 {
					width: 150px;
					height: 100px;
				}
				.about-logo4 {
					width: 100%;
					height: 300px;
				}
				.t1 {
					margin-top: 30px;
					font-size: 30px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #111111;
					line-height: 50px;
				}
				.t2 {
					font-size: 20px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #111111;
					line-height: 30px;
				}
				.t3 {
					margin: 10px 0;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #111111;
					line-height: 24px;
				}
				.t4 {
					margin: 10px 0;
					font-size: 12px;
					font-family: Source Han Sans CN;
					font-weight: 500;
					color: #111111;
				}
				
				.c1 {
					color: #0E82D9;
					font-size: 20px;
					font-family: Montserrat;
					font-weight: bold;
					line-height: 30px;
				}
				
				.p1 {
					font-size: 24px;
					font-family: Montserrat;
					font-weight: bold;
					color: #000000;
					line-height: 40px;
				}
				
				.blue-line {
					margin: 15px 0;
					width: 50px;
					height: 2px;
					background: #0E82D9;
				}
			}
		}
			
		.foot {
			margin-top: 30px;
			height: 370px;
			background-color: #0E82D9;
			
			.foot-content {
				display: flex;
				flex-direction: column;
				width: 100%;
				.foot-scan {
					padding: 20px 0;
					text-align: center;
					font-size: 12px;
					color: #FFFFFF;
				}
				.foot-text {
					margin-top: 20px;
					text-align: center;
					font-size: 12px;
				}
				
				.foot-logo {
					width: 120px;
					height: 120px;
				}
			}
		}

		.header .content .nav {
			display: none !important;
		}

		.content {
			justify-content: space-between !important;
			padding: 0 20px;

			.clear {
				display: none;
			}

			.nav-min {
				display: flex !important;
			}

			.langs {
				display: none;
			}
		}
	}
</style>
